.tabby{
  background-color: rgba(0, 110, 0, 0.68);
  position: relative;
  width: 100%;
  height: 60px;
  margin-left: 10px;
  text-align: center;
  /*-webkit-clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%, 15px 50%);*/
  clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%, 15px 50%);
}
.nav-link:hover{
  color: #cbc9c6;
}
.EditFormDescription::after{
  content: " ";
  display: block;
  position: relative;
  height: 1px;
  background: gray;
  width: 100%;
  bottom: 10px;
}
.EditFormDescription{
  padding-bottom: 20px;
}


.nav_link.active{
  height: 60px;
  background-color: rgba(88, 109, 49, 0.64);
  /*color: white;*/
}
.nav_link.disabled{
  height: 60px;
  background-color: #94d638;
  color: white;
}
.nav_link{
  color: white;
}

.icon_text {
  display: none;
  width: content-box;
  background-color: darkgray;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-size: 13px;
  font-weight: normal;
  z-index: 1;
  top: -5px;
  left: 150%;
}
.icon_text::after {
  content: "";
  position: absolute;
  top: 12%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent darkgray transparent transparent;
}
.mouseover_icon {
  position: relative;
  display: inline-block;
  margin-left:5px;
}
.info_icon:hover {
  color: dodgerblue;
}
.mouseover_icon:hover .icon_text{
  display: inline-block;
}
.angle {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 0;
  right: 20%;
  border-top: 10px solid white;
  border-bottom: none;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.format-text{
  font-weight: normal;
  display: inline-block;
}
.format-image{
  display: block;
  max-width: 80px;
  margin-bottom: 10px;
}
.separator{
  width:1px;
  height: 400px;
  border:10px;
  background-color: dimgray;
  margin-left: 65%;
}
.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: 90%;
}
.email-icon:hover{
  color: dodgerblue;
  cursor: pointer;
}
.email-sidebar:hover{
  color: dodgerblue;
  cursor: pointer;
}
.editor-button:hover{
  background-color: dodgerblue;
  border-color: dodgerblue;
}

.menu-filter-link{
  color: #4a5c66;
  font-size: large;
}

.menu-filter-link:hover{
  color: #4a5c66;
  /*background-color: lightgray;*/
  /*border-bottom: 3px dotted #80b100;*/
}
.menu-filter-link.active{
  color: #4a5c66;
  border-bottom: 3px solid #80b100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}